import React from "react";
import clsx from "clsx";
import { Container, Image, Text, Button, ButtonAlt, Icon } from "@atoms";
import HeroPatternAlt from "@svg/HeroPatternAlt";
import HeroPatternCondensed from "@svg/HeroPatternCondensed";

const HeroPattern = ({ separate, color }) => {
  const classes = clsx("flex h-full w-full", {
    "text-teal": color === "teal",
    "text-purple": color === "purple",
    "text-pink": color === "pink",
    "text-white": color === "white",
  });
  return (
    <div className="pointer-events-none absolute inset-0 z-20">
      <div
        className={clsx("absolute flex h-auto items-end ", {
          "-bottom-20 -right-32 w-[26rem] sm:-right-28 sm:w-[30rem] md:-bottom-28 md:w-[32rem]":
            separate,
          "-bottom-6 -right-6 w-[18rem] md:-right-0 md:bottom-0 md:w-[26rem]":
            !separate,
        })}
      >
        {!separate && <HeroPatternAlt className={classes} />}
        {separate && <HeroPatternCondensed className={classes} />}
      </div>
    </div>
  );
};

const PageHero = ({
  heading,
  descriptor,
  button,
  link,
  image,
  color,
  patternColor,
  separate,
  type,
  collection,
  tag,
  audiences,
  externalResource: external,
  className: _className,
}) => {
  const autoColorPick = color === "purple" ? "teal" : "white";
  let medium;
  switch (type) {
    case "tool":
      medium = "Video";
      break;
    case "articleEssay":
      medium = "Article/Essay";
      break;
    case "book":
      medium = "Book";
      break;
    case "video":
      medium = "Video";
      break;
    default:
      medium = "Resource";
      break;
  }
  return (
    <section
      className={clsx(
        "mx-auto flex max-w-7xl justify-center p-4",
        _className
      )}
    >
      <div
        className={clsx(
          "relative w-full overflow-hidden rounded-t-3xl rounded-bl-3xl",
          {
            "rounded-br-3xl py-6 md:py-14": separate,
            "rounded-br-5xl py-12 sm:py-28 md:rounded-br-6xl": !separate,
            "bg-teal": color === "teal" && separate,
            "bg-purple": color === "purple" && separate,
            "bg-yellow": color === "yellow" && separate,
          }
        )}
      >
        {!separate && image && (
          <div className="absolute inset-0">
            <div className="absolute inset-0 z-10 bg-gradient-to-r from-black via-black opacity-50 mix-blend-multiply" />
            <Image image={image} eager fill />
          </div>
        )}
        <Container className="relative z-30">
          <ButtonAlt
            to={`/resources${external ? "#external" : "#internal"}`}
            reverse
            color="teal"
            className="decoration-teal"
          >
            <span className="flex flex-row items-center gap-2">
              <Icon
                name="chevron"
                className="h-2 rotate-180 text-white"
                fitHeight
              />
              <Text variant="body" className="text-white">
                All Resources
              </Text>
            </span>
          </ButtonAlt>
          <div className="mt-6">
            <div className="flex w-full flex-wrap items-center gap-4 sm:gap-8 md:flex-nowrap">
              <div className="relative z-30 order-2 w-full md:order-1 md:w-1/2">
                <div className="flex flex-col space-y-4">
                  <Text
                    variant="h1small"
                    className={clsx({
                      "text-white": color === "purple" || !separate,
                      "text-slate": color === "teal" && separate,
                      "text-purple": color === "yellow" && separate,
                    })}
                  >
                    {heading}
                  </Text>
                  {/* {!!collection.length && (
                    <Text
                      variant="body"
                      className={clsx("font-bold text-yellow")}
                    >
                      {collection.map(c => c.title).join(", ")}
                    </Text>
                  )} */}
                  {!!audiences.length && (
                    <Text
                      variant="body"
                      className={clsx("font-bold text-yellow")}
                    >
                      {`For: ${audiences.map(c => c.title).join(", ")}`}
                    </Text>
                  )}
                  {tag && <Text
                    variant="body"
                    className={clsx("font-bold text-yellow")}
                  >
                    {tag}
                  </Text>}
                  <Text
                    variant="body"
                    className={clsx("font-bold", {
                      "text-white": color === "purple" || !separate,
                      "text-slate": color !== "purple" && separate,
                    })}
                  >
                    {descriptor}
                  </Text>
                </div>
                {(button?.url || link?.url) && (
                  <div className="mt-10 flex gap-x-4">
                    {button?.url && (
                      <Button
                        to={button.url}
                        size="sm"
                        color={
                          color === "purple" && separate ? "white" : "purple"
                        }
                      >
                        {button.text}
                      </Button>
                    )}
                    {link?.url && (
                      <ButtonAlt
                        color="white"
                        to={link.url}
                        className="text-white"
                      >
                        {link.text}
                      </ButtonAlt>
                    )}
                  </div>
                )}
              </div>
              {separate && image && (
                <div className="relative z-10 order-1 w-full md:order-2 md:w-1/2 lg:-my-4">
                  <div className="h-full">
                    <div className="relative h-48 w-full overflow-hidden rounded-t-3xl rounded-bl-3xl rounded-br-6xl xxs:h-32 sm:h-64 md:h-80">
                      <Image image={image} eager fill />
                    </div>
                  </div>
                </div>
              )}
              {separate && !image && (
                <div className="relative z-10 order-2 ml-auto hidden w-1/3 md:block">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Icon name={type} className="h-auto w-1/2 text-teal/50" />
                  </div>
                </div>
              )}
              {separate && (
                <HeroPattern
                  separate={separate}
                  color={patternColor || autoColorPick}
                />
              )}
            </div>
          </div>
        </Container>
        {!separate && (
          <HeroPattern separate={false} color={patternColor || autoColorPick} />
        )}
      </div>
    </section>
  );
};

PageHero.defaultProps = {
  color: "purple",
  separate: true,
};

export default PageHero;
